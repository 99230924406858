import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-companysignup',
  templateUrl: './companysignup.component.html',
  styleUrls: ['./companysignup.component.css']
})
export class CompanysignupComponent implements OnInit {

  constructor(private formBuilder : FormBuilder,private Signup : SignupService,private router: Router) { }
  Msg;
  RespMsg;
  form: FormGroup;
  ngOnInit() {
    let Otp = localStorage.getItem("Otp");
    if (Otp === '' || Otp === null) {
      this.router.navigate(['/login']);
    }
    this.InitForm();
  }
  InitForm()
  {
    this.form = this.formBuilder.group({
      company:['', Validators.required],
      address:['', Validators.required],
      gst_no:['', Validators.required],
      no_emp:['', Validators.required]
    });
  }
  onSignupUpdate(clientData)
  {
    this.Msg ='Wait';
    //console.log(clientData);
    const formData = new FormData();
    formData.append('company', clientData.company);
    formData.append('address', clientData.address);
    formData.append('gst_no', clientData.gst_no);
    formData.append('no_emp', clientData.no_emp);
    formData.append('UID', localStorage.getItem("UID"));
    this.Signup.SignupUpdate(formData).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        this.InitForm();
        if(res.code==1){
        localStorage.removeItem('Otp');
        localStorage.setItem("LID",res.UID);
        this.router.navigate(['/Pricing']);
        }
        this.Msg ='';
      }
    );  
  }

}
