import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
var baseurl='https://www.crm.gobuzy.com/API/';
@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private http : HttpClient) { }
  public SignupPost(signup)
  {
    let URL = baseurl+"signup.php";
    return this.http.post(URL, signup);
  }
  public SignupVerify(signup)
  {
    let URL = baseurl+"signupverify.php";
    return this.http.post(URL, signup);
  }
  public SignupUpdate(signup)
  {
    let URL = baseurl+"signupupdate.php";
    return this.http.post(URL, signup);
  }
  public LoginActivity(signup)
  {
    let URL = baseurl+"login.php";
    return this.http.post(URL, signup);
  }
  public VerifyActivity(signup)
  {
    let UserId = localStorage.getItem("UID");
    let usertype = localStorage.getItem("usertype");
    let headers = new HttpHeaders({'UserId':UserId,'usertype':usertype});
    let URL = baseurl+"verify.php";
    //return this.http.post(URL, signup);
    return this.http.post(URL, signup,{headers});
  }
  public LoginCheckToken()
  {
    let login = {'username':'','password':''};
    let token = localStorage.getItem("token");
    let UserId = localStorage.getItem("LID");
    let usertype = localStorage.getItem("usertype");
    let headers = new HttpHeaders({'UserId':UserId,'usertype':usertype,'token':token});
    let URL = baseurl+"loginCheck.php";
    return this.http.post(URL, login,{headers});    
  }
  public DashboardActivity(signup)
  {
    let URL = baseurl+"Dashboard.php";
    return this.http.post(URL, signup);
  }
  public GetSettings(signup)
  {
    let URL = baseurl+"Settings.php";
    return this.http.post(URL, signup);
  }
  public CreateLeadActivity(signup)
  {
    let URL = baseurl+"CreateLead.php";
    return this.http.post(URL, signup);
  }
  
  public LeadRemarksActivity(signup)
  {
    let URL = baseurl+"SubmitRemarks.php";
    return this.http.post(URL, signup);
  }
  
  public LeadUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateLead.php";
    return this.http.post(URL, signup);
  }
  public GetLeads_Filter(usertype,Userid,dtype,froms,tos,executive,source,project,feedback,rating,tag_emp,city)
  {
    let URLString = baseurl+'/AllLeadsFilter.php?Usertype='+usertype+'&id='+Userid+'&dtype='+dtype+'&from='+froms+'&to='+tos+'&executive='+executive+'&source='+source+'&project='+project+'&feedback='+feedback+'&rating='+rating+'&tag_emp='+tag_emp+'&city='+city;
    return this.http.get(URLString);
  }
  public GetLeads(usertype,Userid)
  {
    let URLString = baseurl+'/AllLeads.php?Usertype='+usertype+'&id='+Userid;  
    return this.http.get(URLString);
  }
  public GetLeads_Mobile(usertype,Userid,mobile)
  {
    let URLString = baseurl+'/SearchLeads.php?Usertype='+usertype+'&id='+Userid+'&mobile='+mobile;  
    return this.http.get(URLString);
  }
  public GetFeedback(usertype,Userid)
  {
    let URLString = baseurl+'/AllFeedback.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  }
  public UpdateImportant(lid,Important)
  {
    let URLString = baseurl+'/LeadsUpdatePart.php?lid='+lid+'&types='+Important;
    return this.http.get(URLString);
  }
  public FeedbackDetails(signup)
  {
    let URL = baseurl+"FeedbackDetails.php";
    return this.http.post(URL, signup);
  }
  public LeadDetails(signup)
  {
    let URL = baseurl+"LeadDetails.php";
    return this.http.post(URL, signup);
  }
  public FeedbackUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateFeedback.php";
    return this.http.post(URL, signup);
  } 
  public FeedbackRemoveActivitys(Userid)
  {
    let URLString = baseurl+'/RemoveFeedback.php?lid='+Userid;
    return this.http.get(URLString);
  }
  public CreateFeedback(signup)
  {
    let URL = baseurl+"CreateFeedback.php";
    return this.http.post(URL, signup);
  } 
  public DataRemoveActivitys(signup)
  {
    //console.log('URLS', signup);   
    let URL = baseurl+"RemoveData.php";
    return this.http.post(URL, signup);
  }
  public GetSource(usertype,Userid)
  {
    let URLString = baseurl+'/AllSource.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  }
  public SourceDetails(signup)
  {
    let URL = baseurl+"SourceDetails.php";
    return this.http.post(URL, signup);
  } 
  public SourceUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateSource.php";
    return this.http.post(URL, signup);
  }
  public CreateSource(signup)
  {
    let URL = baseurl+"CreateSource.php";
    return this.http.post(URL, signup);
  } 
  public GetStage(usertype,Userid)
  {
    let URLString = baseurl+'/AllStage.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  }
  public StageDetails(signup)
  {
    let URL = baseurl+"StageDetails.php";
    return this.http.post(URL, signup);
  } 
  public StageUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateStage.php";
    return this.http.post(URL, signup);
  } 
  public CreateStage(signup)
  {
    let URL = baseurl+"CreateStage.php";
    return this.http.post(URL, signup);
  }  
  public GetLeadTypes(usertype,Userid)
  {
    let URLString = baseurl+'/AllLeadType.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  } 
  public LeadTypeDetails(signup)
  {
    let URL = baseurl+"LeadTypeDetails.php";
    return this.http.post(URL, signup);
  } 
  public LeadTypeUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateLeadType.php";
    return this.http.post(URL, signup);
  } 
  public CreateLeadType(signup)
  {
    let URL = baseurl+"CreateLeadType.php";
    return this.http.post(URL, signup);
  }  
  public GetServiceTypes(usertype,Userid)
  {
    let URLString = baseurl+'/AllServiceTypes.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  }  
  public ServiceTypeDetails(signup)
  {
    let URL = baseurl+"ServiceTypeDetails.php";
    return this.http.post(URL, signup);
  } 
  public ServicesUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateServices.php";
    return this.http.post(URL, signup);
  } 
  public CreateServices(signup)
  {
    let URL = baseurl+"CreateServices.php";
    return this.http.post(URL, signup);
  } 
  public GetSalesActivity(usertype,Userid)
  {
    let URLString = baseurl+'/AllSalesActivity.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  } 
  public SalesActivityDetails(signup)
  {
    let URL = baseurl+"SalesActivityDetails.php";
    return this.http.post(URL, signup);
  } 
  public SalesUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateActivity.php";
    return this.http.post(URL, signup);
  } 
  public CreateSalesActivity(signup)
  {
    let URL = baseurl+"CreateActivity.php";
    return this.http.post(URL, signup);
  } 
  public GetCityList(usertype,Userid)
  {
    let URLString = baseurl+'/AllCity.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  } 
  public CreateCityActivity(signup)
  {
    let URL = baseurl+"CreateCity.php";
    return this.http.post(URL, signup);
  } 
  public GetLeadTag(usertype,Userid)
  {
    let URLString = baseurl+'/AllLeadTag.php?Usertype='+usertype+'&id='+Userid;
    return this.http.get(URLString);
  } 
  public LeadTagDetails(signup)
  {
    let URL = baseurl+"LeadTagDetails.php";
    return this.http.post(URL, signup);
  } 
  public LeadTagUpdateActivity(signup)
  {
    let URL = baseurl+"UpdateLeadTag.php";
    return this.http.post(URL, signup);
  }
  public CreateLeadTag(signup)
  {
    let URL = baseurl+"CreateLeadTag.php";
    return this.http.post(URL, signup);
  } 
}
