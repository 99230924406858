import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  constructor(private formBuilder : FormBuilder,private Signup : SignupService,private router: Router) { }
  Msg;
  RespMsg;
  form: FormGroup;
  mobile=localStorage.getItem("mobile");
  ngOnInit() {
  //  LID = localStorage.getItem("UID");
  //   if (LID=== '' || LID=== null) {
  //     this.router.navigate(['/Login']);
  //   }
    this.InitForm();
  }
  InitForm()
  {
    this.form = this.formBuilder.group({
      otp:['', Validators.required]
    });
  }
  onVerify(clientData)
  {
    this.Msg ='Wait';
    //console.log(clientData);
    const formData = new FormData();
    formData.append('otp', clientData.otp);
    this.Signup.VerifyActivity(formData).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        this.InitForm();
        if(res.code==1){
        localStorage.setItem("LID",res.id);
        localStorage.setItem("Userid",res.id);
        localStorage.setItem("token",res.token);
        localStorage.setItem("company",res.company);
        localStorage.setItem("Name",res.admin_name);
        this.router.navigate(['/Dashboard']);
        }
        this.Msg ='';
      }
    );  
  }

}
