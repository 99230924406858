import { Component, OnInit} from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-allcity',
  templateUrl: './allcity.component.html',
  styleUrls: ['./allcity.component.css']
})
export class AllcityComponent implements OnInit {
  Msg;
  RespMsg;
  reaponse;
  Feed;
  statuss;
  formR: FormGroup;
  formD: FormGroup;
  formC: FormGroup;
  public temp: Object=false;
  public empData: Object;
  constructor(private formBuilder: FormBuilder,private Signup : SignupService,private router: Router) { }

  ngOnInit() {
  this.LoadData();
  this.reaponse='';
  this.InitSourceRemove();
  this.InitCreateForm();
  }
  
  LoadData() {
    this.Signup.GetCityList(localStorage.getItem('usertype'),localStorage.getItem('Userid')).subscribe((resData) => {
     //console.log(resData.code);
      if(resData['code']==1){
      this.empData= resData['CityList'];
      this.temp = true;
     } else {
      this.empData= "";  
     }
      
    })
  }
 
  
  onRemoveDetails(eid){
    this.RespMsg =null;
    this.Msg =null;
        this.reaponse=eid;
        //console.log(eid); 
  }
  InitSourceRemove()
  {
    this.formD = this.formBuilder.group({
      leadid:[this.reaponse.id,],
      types:['Source',]
    });
  }
  onRemoveSource(clientData){
    this.temp = false; 
    this.Msg ='Wait';
    const postDataR = new FormData();
    postDataR.append('tables','City');
    postDataR.append('lid',clientData);
    this.Signup.DataRemoveActivitys(postDataR).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        if(res.code==1){        
          this.Msg =res.msg;
          this.LoadData();
        }
        this.Msg ='';
      }
      );
  }

  InitCreateForm()
  {
    this.formC = this.formBuilder.group({
      city:['', Validators.required]
    });
  }
  onCreateLeadType(clientData)
  {
    this.temp = false; 
    this.Msg ='Wait';
    //console.log(clientData);
    const formData = new FormData();
    formData.append('city', clientData.city);
    formData.append('id', localStorage.getItem("Userid"));
    formData.append('Usertype',  localStorage.getItem("usertype"));
    this.Signup.CreateCityActivity(formData).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        this.InitCreateForm();
        if(res.code==1){
          this.Msg =res.msg;
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
}
