import { Component, OnInit} from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manageleads',
  templateUrl: './manageleads.component.html',
  styleUrls: ['./manageleads.component.css'],
  providers: [DatePipe]
})
export class ManageleadsComponent implements OnInit {
  Msg;
  RespMsg;
  reaponse;
  Remarks;
  SearchLead;
  SelctedUserId;
  lid;
  SettingDatas;
  SearchMsg;
  form: FormGroup;
  formS : FormGroup;
  formR : FormGroup;
  formD : FormGroup;
  formCr : FormGroup;
  formSR : FormGroup;
  formShare: FormGroup;
  public temp: Object=false;
  public empData: Object;
  public empDataM: Object;
  SourceData:any=[];
  postData:any=[];
  constructor(private formBuilder: FormBuilder,private Signup : SignupService,private router: Router,private datepipe : DatePipe) { }
  ngOnInit() { 
    this.LoadData();
    this.reaponse='';
    this.InitLeadsUpdate();
    this.InitLeadsRemarks();
    this.InitDataRemove();
    this.InitLeadsCreate();
    this.InitSearchLeads();
    this.InitShareLeads();
    const postDatas = new FormData();
    postDatas.append('id', localStorage.getItem("Userid"));
    postDatas.append('Usertype', localStorage.getItem("usertype")); 
    this.Signup.GetSettings(postDatas).subscribe((ResData)=>{
    this.SourceData = ResData;
    }) 
  }
  LoadData() {
    this.temp = false;
    this.Signup.GetLeads(localStorage.getItem('usertype'),localStorage.getItem('Userid')).subscribe((resData) => {
      this.empData= resData;
      this.temp = true;
      //console.log(resData);
    })
  }
  
  UpdateImportant(lid){
    this.Signup.UpdateImportant(lid,'Important').subscribe((resData) => {
      //this.empData= resData;
    })
  }
  InitSearchLeads()
  {
    this.formSR = this.formBuilder.group({
      dtype:['Lead'],
      from:[],
      to:[],
      executive:['Executive'],
      source:['Source'],
      project:['Project'],
      feedback:['Feedback'],
      rating:['Rating'],
      tag_emp:['Tag'],
      city:['City']
    });
  }
  onSearchLeads(clientData){
  this.temp = false;
  let froms=this.datepipe.transform(clientData.from, 'yyyy-MM-dd');
  let tos=this.datepipe.transform(clientData.to, 'yyyy-MM-dd');
  this.Signup.GetLeads_Filter(localStorage.getItem('usertype'),localStorage.getItem('Userid'),clientData.dtype,froms,tos,clientData.executive,clientData.source,clientData.project,clientData.feedback,clientData.rating,clientData.tag_emp,clientData.city).subscribe((resData) => {
    this.empData= resData;
    this.temp = true;
  })
  
  
  }
  OnClearMsg(){
    this.RespMsg=null;
  }
  onSearchMobile(mobile){
    this.Signup.GetLeads_Mobile(localStorage.getItem('usertype'),localStorage.getItem('Userid'),mobile).subscribe(
      (res:any) => {
        this.SearchLead=res.Leads;
        this.SearchMsg=res.code
        //console.log(res);
      }  
    ); 
  }

  onLeadDetails(eid) {
    //this.RespMsg=null;
    this.SelctedUserId = eid;
    const postData = new FormData();
    postData.append('id', eid);
    this.Signup.LeadDetails(postData).subscribe(
      (res:any) => {
        //let dateTimes=this.datepipe.transform(res.nxt_call_date, 'yyyy-MM-dd HH:mm');
        this.reaponse=res;
        this.Remarks=res.Lead_Remarks;
        //console.log(dateTimes);
        this.InitLeadsRemarks();
        this.InitLeadsUpdate(); 
      }  
    ); 
    
    //const postData = new FormData();
   
    
  }
  InitShareLeads(){
    this.formShare = this.formBuilder.group({
      executive:['Executive',Validators.required],
      lid:[this.lid]
    }); 
  }
  onShareLeads(clientData){
    console.log(clientData);
    this.Msg ='Wait';
    const postDataS = new FormData();
    postDataS.append('executive', clientData.executive); 
    postDataS.append('lid', clientData.lid); 
   
    
    // this.Signup.LeadUpdateActivity(postDataS).subscribe(
    //   (res:any) => {
    //     this.RespMsg =res.msg;
    //     if(res.code==1){
    //       this.Msg =res.msg;
    //     }
    //     this.Msg ='';
    //   }
    // );  
  }
  InitLeadsUpdate()
  {
    this.formS = this.formBuilder.group({
      lead_type:[this.reaponse.lead_type,Validators.required],
      name:[this.reaponse.name,Validators.required],
      mobile:[this.reaponse.mobile,Validators.required],
      alt_mobile:[this.reaponse.alt_mobile,],
      email:[this.reaponse.email,],
      source:[this.reaponse.source_id,Validators.required],
      lead_date:[''],
      followupdate:[''],
      executive:[this.reaponse.exe_id,''],
      project:[this.reaponse.project_id,Validators.required],
      rating:[this.reaponse.rating,Validators.required],
      feedback:[this.reaponse.feedback_id,Validators.required],
      stage:[this.reaponse.stage_id, ],
      city:[this.reaponse.city_id, ],
      address:[this.reaponse.address, ]
    });
  }
 
  onUpdateLeads(clientData,lid)
  {
    this.Msg ='Wait';
    //console.log(clientData);
    const postDataS = new FormData();
    postDataS.append('lead_type', clientData.lead_type);
    postDataS.append('name', clientData.name);
    postDataS.append('mobile', clientData.mobile);
    postDataS.append('alt_mobile', clientData.alt_mobile);
    postDataS.append('email', clientData.email);
    postDataS.append('source', clientData.source);
    postDataS.append('lead_date', this.datepipe.transform(clientData.lead_date, 'dd/MM/yyyy HH:mm'));
    postDataS.append('followupdate', this.datepipe.transform(clientData.followupdate, 'dd/MM/yyyy HH:mm'));
    postDataS.append('executive', clientData.executive);
    postDataS.append('project', clientData.project);
    postDataS.append('rating', clientData.rating);
    postDataS.append('feedback', clientData.feedback);
    postDataS.append('stage', clientData.stage);
    postDataS.append('city', clientData.city);
    postDataS.append('address', clientData.address);
    postDataS.append('lid', lid);
    this.Signup.LeadUpdateActivity(postDataS).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        //this.InitForm();
        if(res.code==1){
          this.Msg =res.msg;
          this.onLeadDetails(lid);
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
  InitLeadsCreate()
  {
    this.formCr = this.formBuilder.group({
      lead_type:['', Validators.required],
      name:['', Validators.required],
      mobile:['', Validators.required],
      alt_mobile:['',],
      source:['', Validators.required],
      lead_date:['', Validators.required],
      followup_date:['', Validators.required],
      email:['',],
      project:['',],
      rating:['', Validators.required],
      feedback:['', Validators.required],
      stage:['', ],
      address:['',],
      city:['', ],
      remarks:['', Validators.required] 
    });
  }
  onCreateLeads(clientData)
  {
    this.Msg ='Wait';
    const postDataS = new FormData();
    postDataS.append('lead_type', clientData.lead_type);
    postDataS.append('name', clientData.name);
    postDataS.append('mobile', clientData.mobile);
    postDataS.append('alt_mobile', clientData.alt_mobile);
    postDataS.append('email', clientData.email);
    postDataS.append('source', clientData.source);
    postDataS.append('lead_date', this.datepipe.transform(clientData.lead_date, 'dd/MM/yyyy HH:mm'));
    postDataS.append('followup_date', this.datepipe.transform(clientData.followup_date, 'dd/MM/yyyy HH:mm'));
    postDataS.append('project', clientData.project);
    postDataS.append('rating', clientData.rating);
    postDataS.append('feedback', clientData.feedback);
    postDataS.append('stage', clientData.stage);
    postDataS.append('address', clientData.address);
    postDataS.append('city', clientData.city);
    postDataS.append('remarks', clientData.remarks);
    postDataS.append('id', localStorage.getItem("Userid"));
    postDataS.append('Usertype',  localStorage.getItem("usertype"));
    this.Signup.CreateLeadActivity(postDataS).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        if(res.code==1){
          this.Msg =res.msg;
          this.InitLeadsCreate();
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
  InitLeadsRemarks()
  {
    this.formR = this.formBuilder.group({
      nxt_call_date:[this.reaponse.nxt_call_date,Validators.required],
      project:[this.reaponse.project_id,Validators.required],
      rating:[this.reaponse.rating,Validators.required],
      feedback:[this.reaponse.feedback_id,Validators.required],
      stage:[this.reaponse.stage_id,Validators.required],
      source:[this.reaponse.source_id,Validators.required],
      remarks:['',Validators.required]
    });
  }
  onSubmitRemarks(clientData,lid)
  {
    this.Msg ='Wait';
    //console.log('formS',lid);
    const postDataR = new FormData();
    postDataR.append('nxt_call_date', this.datepipe.transform(clientData.nxt_call_date, 'dd/MM/yyyy HH:mm'));
    postDataR.append('project', clientData.project);
    postDataR.append('rating', clientData.rating);
    postDataR.append('feedback', clientData.feedback);
    postDataR.append('stage', clientData.stage);
    postDataR.append('source', clientData.source);
    postDataR.append('remarks', clientData.remarks);
    postDataR.append('lid', lid);
    postDataR.append('id', localStorage.getItem("Userid"));
    postDataR.append('Usertype',  localStorage.getItem("usertype"));
    this.Signup.LeadRemarksActivity(postDataR).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        //this.InitLeadsRemarks()
        this.onLeadDetails(lid) 
        if(res.code==1){
          this.Msg =res.msg;
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
  onRemoveDetails(eid){
    this.reaponse=eid;
  }
  InitDataRemove()
  {
    this.formD = this.formBuilder.group({
      leadid:[this.reaponse.id,],
      types:['Leads',]
    });
  }
  onRemoveData(clientData){
    //this.temp = false; 
    this.Msg ='Wait';
    const postDataR = new FormData();
    postDataR.append('tables','Leads');
    postDataR.append('lid',clientData);
    this.Signup.DataRemoveActivitys(postDataR).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        if(res.code==1){        
          this.RespMsg =res.msg;
          this.LoadData();
        }
        this.Msg ='';
      }
      );
  }
}
