import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VerifyComponent } from './verify/verify.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignupComponent } from './signup/signup.component';
import { SignupverifyComponent } from './signupverify/signupverify.component';
import { CompanysignupComponent } from './companysignup/companysignup.component';
import { PricingComponent } from './pricing/pricing.component';
import { CreateleadComponent } from './createlead/createlead.component';
import { ManageleadsComponent } from './manageleads/manageleads.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AllsourceComponent } from './allsource/allsource.component';
import { AllstageComponent } from './allstage/allstage.component';
import { LeadtypesComponent } from './leadtypes/leadtypes.component';
import { LeadservicesComponent } from './leadservices/leadservices.component';
import { SalesactivityComponent } from './salesactivity/salesactivity.component';
import { AllcityComponent } from './allcity/allcity.component';
import { TagComponent } from './tag/tag.component'


const routes: Routes = [
  { path: '', component:LoginComponent},
  { path: 'login', component: LoginComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'Dashboard', component: DashboardComponent },
  { path: 'Signup', component: SignupComponent },
  { path: 'SignupVerify', component: SignupverifyComponent },
  { path: 'SignupCompany', component: CompanysignupComponent },
  { path: 'Pricing', component: PricingComponent },
  { path: 'CreateLead', component: CreateleadComponent },
  { path: 'ManageLead', component: ManageleadsComponent },
  { path: 'LeadDetails', component: LeadDetailsComponent },
  { path: 'Feedback', component: FeedbackComponent },
  { path: 'Source', component: AllsourceComponent },
  { path: 'Stages', component: AllstageComponent },
  { path: 'LeadType', component: LeadtypesComponent },
  { path: 'Services', component: LeadservicesComponent },
  { path: 'SalesActivity', component: SalesactivityComponent },
  { path: 'CityList', component: AllcityComponent },
  { path: 'Tag', component: TagComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
