import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-createlead',
  templateUrl: './createlead.component.html',
  styleUrls: ['./createlead.component.css'],
  providers: [DatePipe]
})
export class CreateleadComponent implements OnInit {

  constructor(private formBuilder : FormBuilder,private Signup : SignupService,private router: Router,private datepipe : DatePipe) { }
  Msg;
  RespMsg;
  form: FormGroup;
  SourceData:any=[];

  ngOnInit() {
    const postData = new FormData();
    postData.append('id', localStorage.getItem("Userid"));
    postData.append('Usertype', localStorage.getItem("usertype"));   
    this.Signup.GetSettings(postData).subscribe((ResData)=>{
    this.SourceData = ResData;
    
    }) 
    this.InitForm(); 
  }

  InitForm()
  {
   
    this.form = this.formBuilder.group({
      lead_type:['', Validators.required],
      name:['', Validators.required],
      mobile:['', Validators.required],
      alt_mobile:['',],
      source:['', Validators.required],
      lead_date:['', Validators.required],
      followup_date:['', Validators.required],
      email:['',],
      project:['',],
      rating:['', Validators.required],
      feedback:['', Validators.required],
      stage:['', ],
      address:['',],
      city:['', ],
      remarks:['', Validators.required] 
    });
  }
  
  onCreateLead(clientData)
  {
    this.Msg ='Wait';
    //console.log(clientData);
    const formData = new FormData();
    
    formData.append('lead_type', clientData.lead_type);
    formData.append('name', clientData.name);
    formData.append('mobile', clientData.mobile);
    formData.append('alt_mobile', clientData.alt_mobile);
    formData.append('email', clientData.email);
    formData.append('source', clientData.source);
    formData.append('lead_date', this.datepipe.transform(clientData.lead_date, 'dd/MM/yyyy HH:mm'));
    formData.append('followup_date', this.datepipe.transform(clientData.followup_date, 'dd/MM/yyyy HH:mm'));
    formData.append('project', clientData.project);
    formData.append('rating', clientData.rating);
    formData.append('feedback', clientData.feedback);
    formData.append('stage', clientData.stage);
    formData.append('address', clientData.address);
    formData.append('city', clientData.city);
    formData.append('remarks', clientData.remarks);
    formData.append('id', localStorage.getItem("Userid"));
    formData.append('Usertype',  localStorage.getItem("usertype"));
    this.Signup.CreateLeadActivity(formData).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        this.InitForm();
        if(res.code==1){
          this.Msg =res.msg;
        }
        this.Msg ='';
      }
    );  
  }
}
