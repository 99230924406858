import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(private formBuilder : FormBuilder,private Signup : SignupService,private router: Router) { }
  Msg;
  RespMsg;
  form: FormGroup;
  ngOnInit() {
  this.InitForm();
  }
  InitForm()
  {
    this.form = this.formBuilder.group({
      name:['', Validators.required],
      email:['', Validators.required],
      mobile:['', Validators.required],
      category:['', Validators.required]
    });
  }
  onSignup(clientData)
  {
    this.Msg ='Wait';
    //console.log(clientData);
    const formData = new FormData();
    formData.append('name', clientData.name);
    formData.append('email', clientData.email);
    formData.append('mobile', clientData.mobile);
    formData.append('category', clientData.category);
    this.Signup.SignupPost(formData).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        this.InitForm();
        if(res.code==1){
        localStorage.setItem("Mobile",res.Mobile);
        localStorage.setItem("UID",res.UID);
        localStorage.setItem("Otp",res.Otp);
        this.router.navigate(['/SignupVerify']);
        }
        this.Msg ='';
      }
    );  
  }
  

}
