import { Component, OnInit} from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  Msg;
  RespMsg;
  reaponse;
  Feed;
  statuss;
  formR: FormGroup;
  formD: FormGroup;
  formC: FormGroup;
  public temp: Object=false;
  public empData: Object;
  constructor(private formBuilder: FormBuilder,private Signup : SignupService,private router: Router) { }

  ngOnInit() {
  this.LoadData();
  this.reaponse='';
  this.InitFeedbackUpdate();
  this.InitFeedbackRemove();
  this.InitCreateForm();
  }
  
  LoadData() {
    this.Signup.GetFeedback(localStorage.getItem('usertype'),localStorage.getItem('Userid')).subscribe((resData) => {
      if(resData['code']==1){
        this.empData= resData['ServiceType'];
        this.temp = true;
       } else {
        this.empData= "";  
       }
      
    })
  }
  onFeedbackDetails(eid){
    this.RespMsg =null;
    this.Msg =null;
    const postData = new FormData();
    postData.append('id', eid);
    this.Signup.FeedbackDetails(postData).subscribe(
      (res:any) => {
        this.reaponse=res;
        this.InitFeedbackUpdate();
      }
    );
  }
  InitFeedbackUpdate()
  {
    
    //console.log('status',this.reaponse.status);
    this.formR = this.formBuilder.group({
      feedback:[this.reaponse.feedback,Validators.required],
      status:[this.reaponse.status]
    });
   
  }
  onUpdateFeedback(clientData,lid)
  {
    this.Msg ='Wait';
    const postDataS = new FormData();
    postDataS.append('feedback', clientData.feedback);
    postDataS.append('status', clientData.status);
    postDataS.append('lid', lid);
    this.Signup.FeedbackUpdateActivity(postDataS).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        if(res.code==1){        
          this.Msg =res.msg;
         // console.log(res);
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
  onRemoveDetails(eid){
    this.RespMsg =null;
    this.Msg =null;
        this.reaponse=eid;
        //console.log(eid); 
  }
  InitFeedbackRemove()
  {
    this.formD = this.formBuilder.group({
      leadid:['',]
    });
  }
  onRemoveFeedback(lid){
    this.temp = false;
    this.Msg ='Wait';
    this.Signup.FeedbackRemoveActivitys(lid).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        if(res.code==1){        
          this.Msg =res.msg;
          //console.log(lid);
          this.LoadData();
        }
        this.Msg ='';
      }
      );
  }

  InitCreateForm()
  {
    this.formC = this.formBuilder.group({
      feedback:['', Validators.required],
      status:['Active',]
    });
  }
  onCreateFeedback(clientData)
  {
    this.temp = false; 
    this.Msg ='Wait';
    //console.log(clientData);
    const formData = new FormData();
    formData.append('feedback', clientData.feedback);
    formData.append('status', clientData.status);
    formData.append('id', localStorage.getItem("Userid"));
    formData.append('Usertype',  localStorage.getItem("usertype"));
    this.Signup.CreateFeedback(formData).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        this.InitCreateForm();
        if(res.code==1){
          this.Msg =res.msg;
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
}
