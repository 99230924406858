import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public options: any = {
    title: {
      text: 'Rating Lead Report'
  },
  xAxis: {
      categories: ['Hot', 'Cold', 'Warm', 'No Response', 'Unrelated']
  },
  labels: {
      items: [{
          html: 'Lead Report',
          style: {
              left: '50px',
              top: '18px',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'black'
          }
      }]
  },
  series: [{
      type: 'column',
      name: 'Leads',
      data: [3, 2, 1, 3, 4]
  }, {
      type: 'column',
      name: 'Task',
      data: [2, 3, 5, 7, 6]
  }, {
      type: 'column',
      name: 'New Lead',
      data: [4, 3, 3, 9, 0]
  }, {
      type: 'spline',
      name: 'Average',
      data: [3, 2.67, 3, 6.33, 3.33],
      marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white'
      }
  }, {
      type: 'pie',
      name: 'Total Leads',
      data: [{
          name: 'Leads',
          y: 13,
          color: Highcharts.getOptions().colors[0] // Jane's color
      }, {
          name: 'Task',
          y: 23,
          color: Highcharts.getOptions().colors[1] // John's color
      }, {
          name: 'New Leads',
          y: 19,
          color: Highcharts.getOptions().colors[2] // Joe's color
      }],
      center: [100, 80],
      size: 100,
      showInLegend: false,
      dataLabels: {
          enabled: false
      }
  }]
  }
  public options2: any = {
    chart: {
      type: 'column'
  },
  title: {
      text: 'Monthly Followup Report'
  },
  
  xAxis: {
      categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
      ],
      crosshair: true
  },
  
  tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  },
  plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0
      }
  },
  series: [{
      name: 'Working',
      data: [49, 71, 1064, 1292, 1440, 1760, 1356, 1485, 2164, 1941, 956, 544]

  }, {
      name: 'Closed',
      data: [836, 788, 985, 934, 1060, 845, 1050, 1043, 912, 835, 1066, 923]

  }, {
      name: 'Rejected',
      data: [489, 388, 393, 414, 470, 483, 590, 596, 524, 652, 593, 512]

  }, {
      name: 'Inactive',
      data: [424, 332, 345, 397, 526, 755, 574, 604, 476, 391, 468, 511]

  }]
  }
  constructor(private Signup : SignupService,private router: Router) { }
  TodayFollowup;
  TodayCompleted;
  TodayOverdue;
  TodayTask;
  TaskComplete;
  TaskOverdue;
  NewRecieved;
  SelfGenerate;
  UnusedLead;
  ngOnInit() {
    Highcharts.chart('containerD', this.options);
    Highcharts.chart('containerDD', this.options2);
    const postData = new FormData();
    postData.append('Userid', localStorage.getItem("Userid"));
    postData.append('Usertype', localStorage.getItem("usertype"));
    this.Signup.DashboardActivity(postData).subscribe(
      (res:any) => {
        if(res.code==1){
          this.TodayFollowup=res.TodayFollowup;
          this.TodayCompleted=res.TodayCompleted;
          this.TodayOverdue=res.TodayOverdue;
          this.TodayTask=res.TodayTask;
          this.TaskComplete=res.TaskComplete;
          this.TaskOverdue=res.TaskOverdue;
          this.NewRecieved=res.NewRecieved;
          this.SelfGenerate=res.SelfGenerate;
          this.UnusedLead=res.UnusedLead;
          //console.log('TodayFollowup', res.TodayFollowup);
        } else {
        this.router.navigate(['/login']);
        }
      }
    ); 
  }
 
}
