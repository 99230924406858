import { Component, OnInit} from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-allsource',
  templateUrl: './allsource.component.html',
  styleUrls: ['./allsource.component.css']
})
export class AllsourceComponent implements OnInit {
  Msg;
  RespMsg;
  reaponse;
  Feed;
  statuss;
  formR: FormGroup;
  formD: FormGroup;
  formC: FormGroup;
  public temp: Object=false;
  public empData: Object;
  constructor(private formBuilder: FormBuilder,private Signup : SignupService,private router: Router) { }

  ngOnInit() {
  this.LoadData();
  this.reaponse='';
  this.InitSourceUpdate();
  this.InitSourceRemove();
  this.InitCreateForm();
  }
  
  LoadData() {
    this.Signup.GetSource(localStorage.getItem('usertype'),localStorage.getItem('Userid')).subscribe((resData) => {
      if(resData['code']==1){
        this.empData= resData['ServiceType'];
        this.temp = true;
       } else {
        this.empData= "";  
       }
    })
  }
  onSourceDetails(eid){
    this.RespMsg =null;
    this.Msg =null;
    const postData = new FormData();
    postData.append('id', eid);
    this.Signup.SourceDetails(postData).subscribe(
      (res:any) => {
        this.reaponse=res;
        this.InitSourceUpdate();
      }
    );
  }
  InitSourceUpdate()
  { 
    
    this.formR = this.formBuilder.group({
      source:[this.reaponse.source,Validators.required],
      status:[this.reaponse.status,]
    });
   
  }
  onUpdateSource(clientData,lid)
  {
    this.Msg ='Wait';
    const postDataS = new FormData();
    postDataS.append('source', clientData.source);
    postDataS.append('status', clientData.status);
    postDataS.append('lid', lid);
    this.Signup.SourceUpdateActivity(postDataS).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        console.log(res);
        if(res.code==1){        
          this.Msg =res.msg;
         // console.log(res);
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
  onRemoveDetails(eid){
    this.RespMsg =null;
    this.Msg =null;
        this.reaponse=eid;
        //console.log(eid); 
  }
  InitSourceRemove()
  {
    this.formD = this.formBuilder.group({
      leadid:[this.reaponse.id,],
      types:['Source',]
    });
  }
  onRemoveSource(clientData){
    this.temp = false; 
    this.Msg ='Wait';
    const postDataR = new FormData();
    postDataR.append('tables','Source');
    postDataR.append('lid',clientData);
    this.Signup.DataRemoveActivitys(postDataR).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        if(res.code==1){        
          this.Msg =res.msg;
          this.LoadData();
        }
        this.Msg ='';
      }
      );
  }

  InitCreateForm()
  {
    this.formC = this.formBuilder.group({
      source:['', Validators.required],
      status:['Active',]
    });
  }
  onCreateSource(clientData)
  {
    this.temp = false; 
    this.Msg ='Wait';
    //console.log(clientData);
    const formData = new FormData();
    formData.append('source', clientData.source);
    formData.append('status', clientData.status);
    formData.append('id', localStorage.getItem("Userid"));
    formData.append('Usertype',  localStorage.getItem("usertype"));
    this.Signup.CreateSource(formData).subscribe(
      (res:any) => {
        this.RespMsg =res.msg;
        //console.log(res);
        this.InitCreateForm();
        if(res.code==1){
          this.Msg =res.msg;
          this.LoadData();
        }
        this.Msg ='';
      }
    );  
  }
}
