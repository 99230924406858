import { Component, OnInit } from '@angular/core';
import { SignupService } from '../services/signup.service'
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private Signup : SignupService, private router: Router) { }
  AdminName;
  CompanyName;
  ngOnInit() {
    let Userid = localStorage.getItem("Userid");
    this.AdminName=localStorage.getItem("Name");
    this.CompanyName=localStorage.getItem("company");
    console.log('Userid', Userid);
    if (Userid === '' || Userid === null) {
      this.router.navigate(['/login']);
    }
    else
    {
      this.Signup.LoginCheckToken().subscribe((res:any) => {
        // console.log('Respobse code',res.code);
        //this.LogoutClick();
        if (res.code == "0") {
        this.LogoutClick();
        }
      });
    }
  }
  LogoutClick() {
    console.log('Logout');
    localStorage.removeItem('token');
    localStorage.removeItem('usertype');
    localStorage.removeItem('Userid');
    this.router.navigate(['/login']);
  }

}
