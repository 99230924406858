import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTablesModule } from 'angular-datatables';
import { VerifyComponent } from './verify/verify.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SignupComponent } from './signup/signup.component';
import { SignupverifyComponent } from './signupverify/signupverify.component';
import { CompanysignupComponent } from './companysignup/companysignup.component';
import { PricingComponent } from './pricing/pricing.component';
import { CreateleadComponent } from './createlead/createlead.component';
import { ManageleadsComponent } from './manageleads/manageleads.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AllsourceComponent } from './allsource/allsource.component';
import { AllstageComponent } from './allstage/allstage.component';
import { LeadtypesComponent } from './leadtypes/leadtypes.component';
import { LeadservicesComponent } from './leadservices/leadservices.component';
import { SalesactivityComponent } from './salesactivity/salesactivity.component';
import { AllcityComponent } from './allcity/allcity.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { TagComponent } from './tag/tag.component';


export const MY_MOMENT_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY HH:mm',
  timePickerInput: 'DD/MM/YYYY HH:mm',
  monthYearLabel: 'DD/MM/YYYY HH:mm',
  dateA11yLabel: 'DD/MM/YYYY HH:mm',
  monthYearA11yLabel: 'DD/MM/YYYY HH:mm',
};
export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'DD/MM/YYYY HH:mm',
  monthYearLabel: 'DD/MM/YYYY HH:mm',
  dateA11yLabel: 'DD/MM/YYYY HH:mm',
  monthYearA11yLabel: 'DD/MM/YYYY HH:mm'
};
@NgModule({
  declarations: [
    AppComponent,
    VerifyComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    SignupverifyComponent,
    CompanysignupComponent,
    PricingComponent,
    CreateleadComponent,
    ManageleadsComponent,
    FeedbackComponent,
    AllsourceComponent,
    AllstageComponent,
    LeadtypesComponent,
    LeadservicesComponent,
    SalesactivityComponent,
    AllcityComponent,
    LeadDetailsComponent,
    TagComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
  ],

  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }, 
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'in'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

  
  